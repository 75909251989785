export const contactAddress = "0xD6589D181292AB3fb0cc48C1e0B3c6eF5A6A5A6b"; // testnet

export const contactAbi = [
  {
    type: "constructor",
    stateMutability: "nonpayable",
    inputs: [{ type: "address", name: "_owner", internalType: "address" }],
  },
  {
    type: "event",
    name: "AirdropClaimed",
    inputs: [
      { type: "address", name: "user", internalType: "address", indexed: true },
      {
        type: "uint256",
        name: "amount",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Paused",
    inputs: [
      {
        type: "address",
        name: "account",
        internalType: "address",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Unpaused",
    inputs: [
      {
        type: "address",
        name: "account",
        internalType: "address",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "UserRegistered",
    inputs: [
      { type: "address", name: "user", internalType: "address", indexed: true },
      {
        type: "uint256",
        name: "userId",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "address",
        name: "sponsor",
        internalType: "address",
        indexed: true,
      },
      {
        type: "uint256",
        name: "sponsorId",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "sponserIncome",
    inputs: [
      {
        type: "address",
        name: "from",
        internalType: "address",
        indexed: false,
      },
      { type: "address", name: "to", internalType: "address", indexed: false },
      {
        type: "uint256",
        name: "amount",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "uint256",
        name: "time",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "uint256",
        name: "level",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "sponserIncomeClaimed",
    inputs: [
      {
        type: "address",
        name: "user",
        internalType: "address",
        indexed: false,
      },
      {
        type: "uint256",
        name: "time",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "uint256",
        name: "amount",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "AddressToId",
    inputs: [{ type: "address", name: "", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "contract IERC20" }],
    name: "GCC",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "Idprovider",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [
      { type: "uint256", name: "userId", internalType: "uint256" },
      { type: "uint256", name: "totalAirdropClimed", internalType: "uint256" },
      { type: "address", name: "userReferalAddress", internalType: "address" },
      { type: "uint256", name: "totalDirects", internalType: "uint256" },
      { type: "uint256", name: "incomeRecived", internalType: "uint256" },
      { type: "uint256", name: "totalRewardClaimed", internalType: "uint256" },
    ],
    name: "UserData",
    inputs: [{ type: "address", name: "", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "adminreferal",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    name: "airdropAmount",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "changeAdminReferalId",
    inputs: [{ type: "address", name: "adr", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "changeOwnerAddress",
    inputs: [
      { type: "address", name: "_ownerAddress", internalType: "address" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "claimSponseringIncome",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "idToAddress",
    inputs: [{ type: "uint256", name: "", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "bool", name: "", internalType: "bool" }],
    name: "isUserExist",
    inputs: [{ type: "address", name: "", internalType: "address" }],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "address", name: "", internalType: "address" }],
    name: "owner",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "pauseContract",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "view",
    outputs: [{ type: "bool", name: "", internalType: "bool" }],
    name: "paused",
    inputs: [],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "registerAndClaim",
    inputs: [
      { type: "address", name: "sponserAddress", internalType: "address" },
    ],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "rescueCoinFormContract",
    inputs: [{ type: "uint256", name: "amt", internalType: "uint256" }],
  },
  {
    type: "function",
    stateMutability: "nonpayable",
    outputs: [],
    name: "unPauseContract",
    inputs: [],
  },
  { type: "receive", stateMutability: "payable" },
];
